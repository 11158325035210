






































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CharacterStatCard: () => import('./CharacterStatCard.vue')
  }
})
export default class CharacterStats extends Vue {
  @Prop() data

  generalStats = []
  pvpStats = []
  pvpKeys = [
    'TOTAL_PLAYERS_KILLED', 'TOTAL_DEATHS_BY_PLAYER', 'TOTAL_ARENA_DEATHS', 'TOTAL_ARENA_KILLS',
    'TALENT_ARENA_STARTED', 'TALENT_ARENA_WON', 'TALENT_ARENA_LOSE', 'TALENT_ARENA_SUMMONED',
    'RAINBOW_BATTLE_WON', 'RAINBOW_BATTLE_LOST', 'RAINBOW_BATTLE_KILLS', 'RAINBOW_BATTLE_DEATHS', 'RAINBOW_BATTLE_POINTS',
    'DUEL_WON', 'DUEL_LOST'
  ]

  raids = [
    { name: 'Snowman', iconId: 1371 },
    { name: 'Maru', iconId: 2674 },
    { name: 'Cuby', iconId: 1127 },
    { name: 'Chicken', iconId: 1195 },
    { name: 'Fafnir', iconId: 2905 },
    { name: 'Ginseng', iconId: 1128 },
    { name: 'Castra', iconId: 1129 },
    { name: 'GiantBlackSpider', iconId: 1130 },
    { name: 'Slade', iconId: 1131 },
    { name: 'RobberGang', iconId: 2483 },
    { name: 'JackOLantern', iconId: 1915 },
    { name: 'Namaju', iconId: 1226 },
    { name: 'LordDraco', iconId: 2547 },
    { name: 'Glacerus', iconId: 2583 },
    { name: 'Foxy', iconId: 2662 },
    { name: 'Kertos', iconId: 2460 },
    { name: 'Valakus', iconId: 2461 },
    { name: 'Grenigas', iconId: 2462 },
    { name: 'Laurena', iconId: 2698 },
    { name: 'LolaLopears', iconId: 2716 },
    { name: 'Pirate', iconId: 1440 },
    { name: 'Erenia', iconId: 2751 },
    { name: 'Zenas', iconId: 2750 },
    { name: 'Fernon', iconId: 2878 },
    { name: 'Kirollas', iconId: 4271 },
    { name: 'Carno', iconId: 4272 },
    { name: 'Belial', iconId: 4273 },
    { name: 'Paimon', iconId: 4304 },
    { name: 'Valehir', iconId: 4612 },
    { name: 'Alzanor', iconId: 4615 },
    { name: 'Pollutus', iconId: 7094 },
    { name: 'GiantArma', iconId: 7095 },
    { name: 'UltimateGiantArma', iconId: 7135 },
  ]
  raidsStats = {}

  mounted() {
    const isPvpStat =  e => this.pvpKeys.includes(e.name) && e.name != 'RAID_COMPLETION'

    this.generalStats = this.data.filter(e => !isPvpStat(e) && e.name != 'RAID_COMPLETION')
    this.pvpStats = this.data.filter(isPvpStat)
    this.raidsStats = this.data.find(e => e.name === 'RAID_COMPLETION')?.value
  }

  getRaidAmount(raid) {
    return this.raidsStats[raid] ?? 0
  }
}
